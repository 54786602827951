<script>
import { mapActions } from "vuex";
import SideMenu from "../menus/SideMenu";
import TrelloSearch from "../search/TrelloDynamicSearch";
import { ACTION_DYNAMIC_SOURCE_MANAGER } from "../../data/dialog-keys";
import { clone } from "../../helpers/objects";

export default {
	name: "DynamicSourceManager",
	components: { SideMenu, TrelloSearch },
	computed: {
		dialogKeys: () => ({ ACTION_DYNAMIC_SOURCE_MANAGER }),
		current() {
			if (this.index >= 0 && this.index < this.value.length)
				return this.value[this.index];

			return null;
		}
	},
	props: {
		index: Number,
		value: Array
	},
	methods: {
		...mapActions({
			popDialog: "popDialog",
		}),
		apply({ obj, id, name }) {
			let data = clone(this.value);
			data[this.index].obj = obj;
			data[this.index].id = id;
			data[this.index].name = name;
			this.$emit("input", data);
		},
		applyNone(e) {
			e.preventDefault();
			this.apply({ obj: null });
			this.popDialog();
		},
		applyFromSearch({ obj, id, name }) {
			this.apply({ obj, id, name });
			this.popDialog();
		},
		getSearchControl() {
			switch (this.current.type)
			{
				case "trello-list":
					return <TrelloSearch
						value={this.value[this.index]}
						canOpen={['board']}
						canSelect={['board','list']}
						onInput={this.applyFromSearch}
					/>
			}
		}
	},
	render() {
		if (this.current == null)
			return null;
		
		return <SideMenu dialogKey={ACTION_DYNAMIC_SOURCE_MANAGER}>
			<div class="menu-heading"><i class="fal fa-fw fa-stream"></i>Select Filter</div>
			<div class="menu-item">
				<a class="menu-title" href="#" onClick={this.applyNone}>All</a>
			</div>
			{this.getSearchControl()}
		</SideMenu>;
	}
};
</script>
