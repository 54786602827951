<template>
	<div class="page">
		<DialogMask />

		<h3>Task (Many Actions)</h3>
		<div v-if="loading">
			<h4>Loading...</h4>
		</div>
		<div v-if="entity" class="designer-sequence">
			<TextInput label="Name" v-model="entity.name" />

			<div class="tags">
				<span
					class="tag chip"
					v-for="(tagId, i) in entity.tags"
					:key="i"
				>
					{{ tags.getFromId(tagId).name }}
				</span>
			</div>

			<div v-if="entityActions.length == 0">
				No Actions
			</div>

			<svg
				@click="svgClick"
				height="400"
				width="100%"
				viewBox="0 0 600 400"
			>
				<g v-for="(action, i) in entityActions" :key="i">
					<text x="10" :y="40 * i">{{ i }}</text>
					<text x="40" :y="40 * i">{{ action.name }}</text>
					<text x="300" :y="40 * i">
						{{
							(action.units || []).map(
								(x) =>
									`${unitData[x.type][x.key].prefix}${
										x.value
									}${unitData[x.type][x.key].suffix}`
							)
						}}
					</text>
					<g>
						<IcoEdit
							:x="415"
							:y="40 * i - 16"
							:width="20"
							:height="20"
							style="fill: rgba(80, 80, 80)"
						/>
						<!-- <image x="460" :y="40 * i - 18" width="20" xlink:href="/images/trash.svg" /> -->
						<circle
							data-click="edit"
							:data-index="i"
							cx="425"
							:cy="40 * i - 7.5"
							r="15"
							style="fill: rgba(0, 0, 0, 0); cursor: pointer;"
						/>
					</g>
					<g>
						<IcoTrash
							:x="465"
							:y="40 * i - 16"
							:width="20"
							:height="20"
							style="fill: rgba(230, 80, 80)"
						/>
						<!-- <image x="460" :y="40 * i - 18" width="20" xlink:href="/images/trash.svg" /> -->
						<circle
							data-click="remove"
							:data-index="i"
							cx="475"
							:cy="40 * i - 7.5"
							r="15"
							style="fill: rgba(0, 0, 0, 0); cursor: pointer;"
						/>
					</g>
				</g>

				<g v-for="(loop, i) in entity.loops" :key="`loop_${i}`">
					<line
						x1="0"
						x2="505"
						:y1="40 * loop.from - 24"
						:y2="40 * loop.from - 24"
						style="stroke:rgb(255,150,50,.4);stroke-width:2"
					/>
					<line
						x1="0"
						x2="505"
						:y1="40 * loop.to + 12"
						:y2="40 * loop.to + 12"
						style="stroke:rgb(255,150,50,.4);stroke-width:2"
					/>
					<line
						x1="500"
						x2="500"
						:y1="40 * loop.from - 24"
						:y2="40 * loop.to + 12"
						style="stroke:rgb(255,150,50,.8);stroke-width:10"
					/>
					<text
						x="520"
						:y="40 * loop.from + (40 * (loop.to - loop.from)) / 2"
						style="font-weight: 500;stroke:#888"
					>
						x{{ loop.repeat }}
					</text>
				</g>
			</svg>

			<SideMenu
				:dialogKey="dialogKeys.TASK_ACTIONS"
			>
				<div class="menu">
					<div class="menu-heading">Task Actions</div>
					<div class="menu-subheading">Add Action</div>
					<div class="menu-item">
						<a
							href="#"
							class="menu-title"
							@click.prevent="() => createAction(false)"
							>Create New</a
						>
					</div>
					<div class="menu-divider"></div>

					<div
						class="menu-item"
						v-for="action in entity.inlineActions"
						:key="action.id"
					>
						<a
							class="menu-title"
							href="#"
							@click.prevent="() => addAction(action.id)"
							>{{ action.name }}</a
						>
					</div>

					<div class="menu-subheading">Add Task (Single Action)</div>
						<div class="menu-item">
							<a
								class="menu-title"
								href="#"
								@click.prevent="() => createAction(true)"
								>Create New</a
							>
						</div>
						<div class="menu-divider"></div>
						<div class="menu-item" v-for="action in actions" :key="action.id">
							<a
								class="menu-title"
								href="#"
								@click.prevent="
									() => addTaskAsAction(action.value)
								"
								>{{ action.text }}</a
							>
						</div>
				</div>
			</SideMenu>

			<SideMenu
				:dialogKey="dialogKeys.TASK_LOOPS"
			>
				<div class="menu">
					<div class="menu-heading">Loops</div>
					<div class="menu-subheading">Add</div>
					<div class="menu-item">
						<TextInput
							label="From"
							v-model="loop.from"
							type="number"
						/>
					</div>
					<div class="menu-item">
						<TextInput label="To" v-model="loop.to" type="number" />
					</div>
					<div class="menu-item">
						<TextInput
							label="Repeat"
							v-model="loop.repeat"
							type="number"
						/>
					</div>
					<div class="menu-actions">
						<button @click="addLoop">
							<i class="fal fa-fw fa-plus"></i>
						</button>
					</div>

					<div class="menu-subheading">Applied</div>
					<div
						class="menu-item"
						v-for="(loop, i) in entity.loops"
						:key="i"
					>
						<span>From: {{ loop.from }}, </span>
						<span>To: {{ loop.to }}, </span>
						<span>Repeat: {{ loop.repeat }} </span>
						<a href="#" @click.prevent="() => removeLoop(i)"
							><i class="fal fa-fw fa-trash"></i
						></a>
					</div>
				</div>
			</SideMenu>
		</div>

		<div class="action-bar-container">
			<div class="action-bar-row">
				<div class="action-bar">
					<router-link class="btn" to="/board">
						<i class="fal fa-fw fa-arrow-left"></i>
					</router-link>
					<button @click.prevent="() => save(entity)">
						<i class="fal fa-fw fa-save"></i>
						<label>Save</label>
					</button>
					<button @click="() => setDialog(dialogKeys.TASK_LOOPS)">
						<i class="fal fa-fw fa-repeat"></i>
						<label>Loops</label>
					</button>
					<button @click="() => setDialog(dialogKeys.TASK_ACTIONS)">
						<i class="fal fa-fw fa-credit-card-blank"></i>
						<label>Actions</label>
					</button>
				</div>
			</div>
		</div>
		<ActionDesigner
			:fn-get="getInlineAction"
			:fn-create="createInlineAction"
			:fn-update="updateInlineAction"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TextInput } from "@/components/inputs/index";
import SideMenu from "@/components/menus/SideMenu";
import IcoTrash from "@/components/icons/Trash";
import IcoEdit from "@/components/icons/Edit";
import DialogMask from "@/components/DialogMask";
import { v4 as uuid } from "uuid";

import ActionDesigner from "../../components/designers/ActionDesigner";

import { TASK_ACTIONS, TASK_LOOPS } from "../../data/dialog-keys";

const defaultEntity = JSON.stringify({
	type: "sequence",
	name: "",
	body: "",
	units: [],
	tags: [],
	prep_entityIds: [],
	actionIds: [],
	actions: [],
	inlineActions: {},
	inlineNextId: 1,
	loops: [],
});

export default {
	name: "Sequence",
	props: {
		id: {},
	},
	components: {
		TextInput,
		SideMenu,
		IcoTrash,
		IcoEdit,
		ActionDesigner,
		DialogMask
	},
	computed: {
		...mapGetters({
			dialog: "dialog",
			d: "tasks/data",
			unitData: "unitData",
			tags: "tags/data",
		}),
		dialogKeys: () => ({ TASK_ACTIONS, TASK_LOOPS }),
		actions() {
			return this.d.list
				.filter((e) => ["action", "dynamic-action"].includes(e.type))
				.map((e) => ({
					text: e.name,
					value: e._id,
				}));
		},
		entityActions() {
			return this.entity.actions.map(({ entityId, inlineId }) => {
				if (entityId)
					return {
						...this.d.getFromId(entityId),
						d: "entity",
					};

				if (inlineId)
					return {
						...this.entity.inlineActions[inlineId],
						d: "inline",
					};
			});
		},
	},
	mounted() {
		this.load();
	},
	watch: {
		"$route.path"() {
			this.load();
		},
	},
	data: () => ({
		loading: false,
		entity: JSON.parse(defaultEntity),
		action: {
			name: "",
		},
		editingAction: {
			name: "",
		},
		loop: {
			from: 0,
			to: 0,
			repeat: 2,
		},
		menuActive: null,
		editInlineId: 0,
	}),
	methods: {
		...mapActions({
			setDialog: "setDialog",
			popDialog: "popDialog",
			listEntities: "tasks/list",
			createEntity: "tasks/create",
			updateEntity: "tasks/update",

			listTagsThrottled: "tags/listThrottled",

			success: "broadcastSuccess",

			openSingleActionDesigner: "tasks/openSingleActionDesigner",
		}),
		async load() {
			this.loading = true;
			this.entity = JSON.parse(defaultEntity);
			if (this.id) {
				await this.listEntities();
				await this.listTagsThrottled();
				const e = this.d.getFromId(this.id);
				this.entity = { ...this.entity, ...e };
			}

			// generate ref ids for inline actions (if null)
			Object.keys(this.entity.inlineActions).forEach(key => {
				let action = this.entity.inlineActions[key];
				if (!action.refId)
					action.refId = uuid();
			});

			// apply missing inline action ref ids to action list
			this.entity.actions.forEach(action => {
				if (action.inlineId && !action.refId)
				{
					const data = this.entity.inlineActions[action.inlineId];
					action.refId = data.refId;
				}
			});
			
			this.loading = false;
		},
		async save(entity) {
			this.loading = true;
			if (!this.id) {
				await this.createEntity(entity);
				this.success("Created!");
				this.$router.push("/board");
			} else {
				await this.updateEntity({ id: this.id, data: entity });
				this.success("Updated!");
			}
			this.loading = false;
		},

		// start action designer interface

		updateInlineAction(
			id,
			{ name, units, tags, isDynamic, dynamicSources }
		) {
			console.log("update", id, name, units);
			const action = this.entity.inlineActions[id];
			action.name = name;
			action.units = units;
			action.tags = tags;
			action.isDynamic = isDynamic;
			action.dynamicSources = dynamicSources;
		},
		createInlineAction({ name, units, tags, isDynamic, dynamicSources }) {
			const id = this.entity.inlineNextId++;
			console.log("create", id, name, units);
			this.entity.inlineActions[id] = {
				id,
				name,
				units,
				tags,
				isDynamic,
				dynamicSources,
			};
			this.entity.actions.push({ inlineId: id });
		},
		getInlineAction(id) {
			return this.entity.inlineActions[id];
		},

		// end action designer interface

		addAction(id) {
			this.entity.actions.push({
				inlineId: id,
			});
		},
		addTaskAsAction(id) {
			this.entity.actions.push({
				entityId: id,
			});
		},
		addLocalAction() {
			const { name } = this.action;
			if (!name) return;

			const id = this.entity.inlineNextId++;
			this.entity.inlineActions[id] = { id, name, units: [] };
			this.entity.actions.push({
				inlineId: id,
			});
			this.action.name = "";
		},
		removeAction(i) {
			this.entity.actions.splice(i, 1);
		},

		repeatDragStart() {},
		repeatDragOver(e) {
			e.preventDefault();
		},
		repeatDrop(e) {
			e.preventDefault();
			console.log(e);
		},

		addLoop() {
			this.entity.loops.push({ ...this.loop });
			this.loop.from = 0;
			this.loop.to = 0;
			this.loop.repeat = 2;
		},
		removeLoop(index) {
			this.entity.loops.splice(index, 1);
		},
		toggleMenu(name) {
			if (this.menuActive != name) this.menuActive = name;
			else this.menuActive = null;
		},

		svgClick(e) {
			const click = e.target.dataset.click;
			const index = parseInt(e.target.dataset.index);

			if (click) {
				switch (click) {
					case "remove":
						this.removeAction(index);
						return;

					case "edit":
						this.editAction(this.entity.actions[index]);
						return;
				}
			}
		},

		createAction(isTask) {
			this.openSingleActionDesigner({
				type: "action",
				id: null,
				inlineId: null,
				isTask,
			});
		},

		editAction(action) {
			if (action.inlineId) {
				this.openSingleActionDesigner({
					type: "action",
					inlineId: action.inlineId,
					isTask: false,
				});

				// this.editingAction = { name: "", units: [], ...this.entity.inlineActions[action.inlineId] };
				// this.editInlineId = action.inlineId;
				return;
			}

			if (action.entityId) {
				// open single action editor
				this.openSingleActionDesigner({
					type: "action",
					id: action.entityId,
					isTask: true,
				});

				return;
			}
		},
		applyInlineAction() {
			this.entity.inlineActions[this.editInlineId] = this.editingAction;
			this.editInlineId = 0;
		},
		addUnit({ type, key }) {
			this.editingAction.units.push({ type, key, value: 1 });
		},
	},
};
</script>
