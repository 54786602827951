<template>
	<svg :x="x" :y="y" :width="width" :height="height" viewBox="0 0 450 512">
		<path :x="x" :y="y" :width="width" :height="height"
			d="M440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h18.9l33.2 372.3a48 48 0 0 0 47.8 43.7h232.2a48 48 0 0 0 47.8-43.7L421.1 96H440a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zm184.8 427a15.91 15.91 0 0 1-15.9 14.6H107.9A15.91 15.91 0 0 1 92 465.4L59 96h330z"
		/>
	</svg>
</template>

<script>
export default {
    props: {
        x: Number,
        y: Number,
        width: Number,
        height: Number
    }
}
</script>