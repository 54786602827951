var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"dialog-anim"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active && _vm.context),expression:"active && context"}],staticClass:"dialog dialog--task-single active",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"dialog-heading",domProps:{"textContent":_vm._s(_vm.heading)}}),_c('div',{staticClass:"dialog-body"},[_c('TextInput',{attrs:{"label":"Action Name"},model:{value:(_vm.params.name),callback:function ($$v) {_vm.$set(_vm.params, "name", $$v)},expression:"params.name"}}),_c('Card',{attrs:{"name":"Type","state":_vm.params.positive ? 'Good' : 'Bad',"theme":_vm.params.positive ? 'good' : 'bad',"actions":[
						{
							fnClick: function () { return (_vm.params.positive = true); },
							icon: 'angel',
							active: _vm.params.positive,
						},
						{
							fnClick: function () { return (_vm.params.positive = false); },
							icon: 'skull',
							active: !_vm.params.positive,
						} ]}}),_c('div',{staticClass:"v-spacer"}),_c('Card',{staticClass:"mt-2",attrs:{"name":"Effort","state":_vm.effortText,"theme":_vm.params.positive ? 'good' : 'bad',"actions":[
						{
							fnClick: function () { return (_vm.params.effort = 0); },
							icon: 'meh-blank',
							active: _vm.params.effort == 0,
							badge: '+0'
						},
						{
							fnClick: function () { return (_vm.params.effort = 1); },
							icon: 'award',
							active: _vm.params.effort == 1,
							badge: '+1'
						},
						{
							fnClick: function () { return (_vm.params.effort = 2); },
							icon: 'medal',
							active: _vm.params.effort == 2,
							badge: '+2'
						},
						{
							fnClick: function () { return (_vm.params.effort = 3); },
							icon: 'trophy',
							active: _vm.params.effort == 3,
							badge: '+3'
						} ]}}),_c('div',{staticClass:"v-spacer"}),_c('Card',{staticClass:"mt-2 heading",attrs:{"name":"Habits","fnClick":function () { return (_vm.showHabits = !_vm.showHabits); },"actions":[
						{
							icon: 'plus',
							fnClick: function () { return _vm.pushDialog('action-add-habit'); }
						},
						{
							fnClick: function () { return (_vm.showHabits = !_vm.showHabits); },
							icon: _vm.showHabits ? 'angle-down' : 'angle-right',
							badge: !_vm.showHabits && _vm.params.habits.length.toString()
						} ]}}),_c('transition',[(_vm.showHabits)?_c('div',{staticClass:"indent"},_vm._l((_vm.params.habits),function(habit,i){return _c('Card',{key:("habit-effect_" + i),staticClass:"light",attrs:{"name":_vm.habits.get(habit.refId).name,"state":(habit.invert ? _vm.negativeOperator : _vm.positiveOperator) + _vm.params.effort,"actions":[
								{
									fnClick: function () { return habit.invert = !habit.invert; },
									icon: 'sync',
									active: habit.invert
								},
								{
									fnClick: function () { return _vm.params.habits.splice(i, 1); },
									icon: 'trash',
								} ]}})}),1):_vm._e()]),_c('div',{staticClass:"v-spacer"}),_c('Card',{staticClass:"mt-2 heading",attrs:{"name":"Data Sources","fnClick":function () { return (_vm.showDataSources = !_vm.showDataSources); },"actions":[
						{
							icon: 'plus',
							fnClick: function () { return _vm.pushDialog(
									_vm.dialogKeys.ACTION_DYNAMIC_SOURCES
								); },
						},
						{
							fnClick: function () { return (_vm.showDataSources = !_vm.showDataSources); },
							icon: _vm.showDataSources ? 'angle-down' : 'angle-right',
							badge: !_vm.showDataSources && _vm.params.dynamicSources.length.toString()
						} ]}}),_c('transition',[(_vm.showDataSources)?_c('div',{staticClass:"indent"},_vm._l((_vm.params.dynamicSources),function(source,i){return _c('Card',{key:("source_" + i),staticClass:"light",attrs:{"name":_vm.sourceData[source.type].name,"state":_vm.params.dynamicSources[i].name || 'All',"fnClick":function () { return _vm.handleSourceClick(i); },"actions":[
								{
									fnClick: function () { return _vm.params.dynamicSources.splice(i, 1); },
									icon: 'trash',
								} ]}})}),1):_vm._e()]),_c('div',{staticClass:"v-spacer"}),_c('Card',{staticClass:"mt-2 heading",attrs:{"name":"Units","fnClick":function () { return (_vm.showUnits = !_vm.showUnits); },"actions":[
						{
							icon: 'plus',
							fnClick: function () { return _vm.pushDialog(_vm.dialogKeys.ACTION_UNITS); },
						},
						{
							fnClick: function () { return (_vm.showUnits = !_vm.showUnits); },
							icon: _vm.showUnits ? 'angle-down' : 'angle-right',
							badge: !_vm.showUnits && _vm.params.units.length.toString()
						} ]}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUnits),expression:"showUnits"}],staticClass:"indent"},[_c('UnitManager',{model:{value:(_vm.params.units),callback:function ($$v) {_vm.$set(_vm.params, "units", $$v)},expression:"params.units"}})],1),_c('div',{staticClass:"v-spacer"}),_c('Card',{staticClass:"mt-2 heading",attrs:{"name":"Tags","fnClick":function () { return (_vm.showTags = !_vm.showTags); },"actions":[
						{
							icon: 'plus',
							fnClick: function () { return _vm.pushDialog('action-add-tag'); }
						},
						{
							fnClick: function () { return (_vm.showTags = !_vm.showTags); },
							icon: _vm.showTags ? 'angle-down' : 'angle-right',
							badge: !_vm.showTags && _vm.params.tags.length.toString()
						} ]}}),_c('transition',[(_vm.showTags)?_c('div',{staticClass:"indent"},_vm._l((_vm.params.tags),function(tagId,i){return _c('Card',{key:("tag_" + i),staticClass:"light",attrs:{"name":_vm.tags.get(tagId).name,"actions":[
								{
									fnClick: function () { return _vm.params.tags.splice(i, 1); },
									icon: 'trash',
								} ]}})}),1):_vm._e()]),_c('div',{staticClass:"v-spacer"}),_c('Card',{staticClass:"mt-2 heading",attrs:{"name":"Schedules","fnClick":function () { return _vm.showSchedules = !_vm.showSchedules; },"actions":[
						{
							fnClick: function () { return _vm.openAddSchedule(); },
							icon: 'plus',
						},
						{
							fnClick: function () { return (_vm.showSchedules = !_vm.showSchedules); },
							icon: _vm.showSchedules ? 'angle-down' : 'angle-right',
							badge: !_vm.showSchedules && _vm.params.schedules.length.toString()
						} ]}}),(_vm.showSchedules)?_c('div',{staticClass:"indent"},_vm._l((_vm.params.schedules),function(schedule,i){return _c('Card',{key:("action-schedule_" + i),staticClass:"card-h elevation-1 light mt-2",attrs:{"fnClick":function () { return _vm.openUpdateSchedule(i); },"name":schedule.name,"state":schedule.desc,"actions":[
						{
							fnClick: function () { return _vm.removeSchedule(i); },
							icon: 'trash'
						}
					]}})}),1):_vm._e(),_c('div',{staticClass:"dialog-actions"},[_c('Card',{staticClass:"button",attrs:{"name":!_vm.hasParent ? 'Save' : 'Apply',"fnClick":_vm.save,"icon":"save"}})],1)],1)])]),_c('SideMenu',{attrs:{"dialogKey":_vm.dialogKeys.ACTION_DYNAMIC_SOURCES}},[_c('div',{staticClass:"menu-heading"},[_vm._v("Data Sources")]),_vm._l((_vm.dynamicSourceOptions),function(source,j){return _c('div',{key:("source-option_" + j),staticClass:"menu-item"},[_c('a',{staticClass:"menu-title",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.addDataSource(source); })($event)}}},[_vm._v(" "+_vm._s(_vm.sourceData[source.type].name)+" ")])])})],2),_c('SideMenu',{attrs:{"dialogKey":_vm.dialogKeys.ACTION_UNITS}},[_c('div',{staticClass:"menu-heading"},[_vm._v("Units")]),_c('div',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-title",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addUnit({ type: 'time', key: 's' })}}},[_vm._v("Time")])]),_c('div',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-title",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addUnit({
						type: 'weight',
						key: 'g',
					})}}},[_vm._v("Weight")])]),_c('div',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-title",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addUnit({
						type: 'liquid',
						key: 'ml',
					})}}},[_vm._v("Liquid")])]),_c('div',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-title",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addUnit({
						type: 'quantity',
						key: 'n',
					})}}},[_vm._v("Quantity")])])]),_c('SideMenu',{attrs:{"dialogKey":"action-add-tag"}},[_c('div',{staticClass:"dialog-heading"},[_vm._v("Add Tag")]),_c('div',{staticClass:"dialog-body"},_vm._l((_vm.tags.list.filter(function (x) { return !_vm.params.tags.includes(x.refId); })),function(tag,i){return _c('Card',{key:("add-tag_" + i),attrs:{"name":tag.name,"fnClick":function () {
					_vm.params.tags.push(tag.refId);
					_vm.showTags = true;
					_vm.popDialog();
				}}})}),1)]),_c('SideMenu',{attrs:{"dialogKey":"action-add-habit"}},[_c('div',{staticClass:"dialog-heading"},[_vm._v("Add Habit Effect")]),_c('div',{staticClass:"dialog-body"},_vm._l((_vm.habits.list.filter(function (x) { return !_vm.params.habits.includes(x.refId); })),function(habit,i){return _c('Card',{key:("add-habit-effect_" + i),attrs:{"name":habit.name,"fnClick":function () {
					_vm.params.habits.push({ refId: habit.refId, invert: false });
					_vm.showHabits = true;
					_vm.popDialog();
				}}})}),1)]),_c('DynamicSourceManager',{attrs:{"index":_vm.dynamicSourceIndex},model:{value:(_vm.params.dynamicSources),callback:function ($$v) {_vm.$set(_vm.params, "dynamicSources", $$v)},expression:"params.dynamicSources"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }