<template>
	<div>
		<transition name="dialog-anim">
			<div
				v-show="active && context"
				class="dialog dialog--task-single active"
				@click.stop
			>
				<div class="dialog-heading" v-text="heading"></div>

				<div class="dialog-body">
					<TextInput label="Action Name" v-model="params.name" />

					<Card
						class=""
						name="Type"
						:state="params.positive ? 'Good' : 'Bad'"
						:theme="params.positive ? 'good' : 'bad'"
						:actions="[
							{
								fnClick: () => (params.positive = true),
								icon: 'angel',
								active: params.positive,
							},
							{
								fnClick: () => (params.positive = false),
								icon: 'skull',
								active: !params.positive,
							},
						]"
					/>

					<div class="v-spacer"></div>

					<Card
						name="Effort"
						class="mt-2"
						:state="effortText"
						:theme="params.positive ? 'good' : 'bad'"
						:actions="[
							{
								fnClick: () => (params.effort = 0),
								icon: 'meh-blank',
								active: params.effort == 0,
								badge: '+0'
							},
							{
								fnClick: () => (params.effort = 1),
								icon: 'award',
								active: params.effort == 1,
								badge: '+1'
							},
							{
								fnClick: () => (params.effort = 2),
								icon: 'medal',
								active: params.effort == 2,
								badge: '+2'
							},
							{
								fnClick: () => (params.effort = 3),
								icon: 'trophy',
								active: params.effort == 3,
								badge: '+3'
							},
						]"
					/>

					<div class="v-spacer"></div>

					<Card
						class="mt-2 heading"
						name="Habits"
						:fnClick="() => (showHabits = !showHabits)"
						:actions="[
							{
								icon: 'plus',
								fnClick: () => pushDialog('action-add-habit')
							},
							{
								fnClick: () => (showHabits = !showHabits),
								icon: showHabits ? 'angle-down' : 'angle-right',
								badge: !showHabits && params.habits.length.toString()
							},
						]"
					/>

					<transition>
						<div class="indent" v-if="showHabits">

							<Card
								v-for="(habit, i) in params.habits"
								:key="`habit-effect_${i}`"
								:name="habits.get(habit.refId).name"
								class="light"
								:state="(habit.invert ? negativeOperator : positiveOperator) + params.effort"
								:actions="[
									{
										fnClick: () => habit.invert = !habit.invert,
										icon: 'sync',
										active: habit.invert
									},
									{
										fnClick: () =>
											params.habits.splice(i, 1),
										icon: 'trash',
									},
								]"
							/>

						</div>
					</transition>

					<div class="v-spacer"></div>

					<Card
						class="mt-2 heading"
						name="Data Sources"
						:fnClick="() => (showDataSources = !showDataSources)"
						:actions="[
							{
								icon: 'plus',
								fnClick: () =>
									pushDialog(
										dialogKeys.ACTION_DYNAMIC_SOURCES
									),
							},
							{
								fnClick: () => (showDataSources = !showDataSources),
								icon: showDataSources ? 'angle-down' : 'angle-right',
								badge: !showDataSources && params.dynamicSources.length.toString()
							},
						]"
					/>

					<transition>
						<div class="indent" v-if="showDataSources">

							<Card
								v-for="(source, i) in params.dynamicSources"
								:key="`source_${i}`"
								:name="sourceData[source.type].name"
								class="light"
								:state="params.dynamicSources[i].name || 'All'"
								:fnClick="() => handleSourceClick(i)"
								:actions="[
									{
										fnClick: () =>
											params.dynamicSources.splice(i, 1),
										icon: 'trash',
									},
								]"
							/>

						</div>
					</transition>

					<div class="v-spacer"></div>

					<Card
						class="mt-2 heading"
						name="Units"
						:fnClick="() => (showUnits = !showUnits)"
						:actions="[
							{
								icon: 'plus',
								fnClick: () =>
									pushDialog(dialogKeys.ACTION_UNITS),
							},
							{
								fnClick: () => (showUnits = !showUnits),
								icon: showUnits ? 'angle-down' : 'angle-right',
								badge: !showUnits && params.units.length.toString()
							},
						]"
					/>

					<div v-show="showUnits" class="indent">
						<UnitManager v-model="params.units" />
					</div>

					<div class="v-spacer"></div>

					<Card
						class="mt-2 heading"
						name="Tags"
						:fnClick="() => (showTags = !showTags)"
						:actions="[
							{
								icon: 'plus',
								fnClick: () => pushDialog('action-add-tag')
							},
							{
								fnClick: () => (showTags = !showTags),
								icon: showTags ? 'angle-down' : 'angle-right',
								badge: !showTags && params.tags.length.toString()
							},
						]"
					/>

					<transition>
						<div class="indent" v-if="showTags">

							<Card
								v-for="(tagId, i) in params.tags"
								:key="`tag_${i}`"
								:name="tags.get(tagId).name"
								class="light"
								:actions="[
									{
										fnClick: () =>
											params.tags.splice(i, 1),
										icon: 'trash',
									},
								]"
							/>

						</div>
					</transition>

					<div class="v-spacer"></div>

					<Card
						name="Schedules"
						class="mt-2 heading"
						:fnClick="() => showSchedules = !showSchedules"
						:actions="[
							{
								fnClick: () => openAddSchedule(),
								icon: 'plus',
							},
							{
								fnClick: () => (showSchedules = !showSchedules),
								icon: showSchedules ? 'angle-down' : 'angle-right',
								badge: !showSchedules && params.schedules.length.toString()
							},
						]"
					/>

					<div v-if="showSchedules" class="indent">
					<Card
						v-for="(schedule,i) in params.schedules"
						:key="`action-schedule_${i}`"
						:fnClick="() => openUpdateSchedule(i)"
						:name="schedule.name"
						class="card-h elevation-1 light mt-2"
						:state="schedule.desc"
						:actions="[
							{
								fnClick: () => removeSchedule(i),
								icon: 'trash'
							}
						]"
						/>
					</div>

					<div class="dialog-actions">
						<Card :name="!hasParent ? 'Save' : 'Apply'" class="button" :fnClick="save" icon="save" />
					</div>
				</div>
			</div>
		</transition>

		<SideMenu :dialogKey="dialogKeys.ACTION_DYNAMIC_SOURCES">
			<div class="menu-heading">Data Sources</div>
			<div
				class="menu-item"
				v-for="(source, j) in dynamicSourceOptions"
				:key="`source-option_${j}`"
			>
				<a
					class="menu-title"
					href="#"
					@click.prevent="() => addDataSource(source)"
				>
					{{ sourceData[source.type].name }}
				</a>
			</div>
		</SideMenu>

		<SideMenu :dialogKey="dialogKeys.ACTION_UNITS">
			<div class="menu-heading">Units</div>

			<div class="menu-item">
				<a
					class="menu-title"
					href="#"
					@click.prevent="addUnit({ type: 'time', key: 's' })"
					>Time</a
				>
			</div>
			<div class="menu-item">
				<a
					class="menu-title"
					href="#"
					@click.prevent="
						addUnit({
							type: 'weight',
							key: 'g',
						})
					"
					>Weight</a
				>
			</div>
			<div class="menu-item">
				<a
					class="menu-title"
					href="#"
					@click.prevent="
						addUnit({
							type: 'liquid',
							key: 'ml',
						})
					"
					>Liquid</a
				>
			</div>
			<div class="menu-item">
				<a
					class="menu-title"
					href="#"
					@click.prevent="
						addUnit({
							type: 'quantity',
							key: 'n',
						})
					"
					>Quantity</a
				>
			</div>
		</SideMenu>

		<SideMenu dialogKey="action-add-tag">
			<div class="dialog-heading">Add Tag</div>
			<div class="dialog-body">
				<Card
					v-for="(tag,i) in tags.list.filter(x => !params.tags.includes(x.refId))"
					:key="`add-tag_${i}`"
					:name="tag.name"
					:fnClick="() => {
						params.tags.push(tag.refId);
						showTags = true;
						popDialog();
					}"
					/>
			</div>
		</SideMenu>

		<SideMenu dialogKey="action-add-habit">
			<div class="dialog-heading">Add Habit Effect</div>
			<div class="dialog-body">
				<Card
					v-for="(habit,i) in habits.list.filter(x => !params.habits.includes(x.refId))"
					:key="`add-habit-effect_${i}`"
					:name="habit.name"
					:fnClick="() => {
						params.habits.push({ refId: habit.refId, invert: false });
						showHabits = true;
						popDialog();
					}"
					/>
			</div>
		</SideMenu>

		<DynamicSourceManager
			v-model="params.dynamicSources"
			:index="dynamicSourceIndex"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TextInput } from "../../components/inputs/index";
import { dynamicSourceOptions } from "../../helpers/dynamic-action";
import {
	ACTION,
	ACTION_DYNAMIC_SOURCES,
	ACTION_DYNAMIC_SOURCE_MANAGER,
	ACTION_UNITS,
} from "../../data/dialog-keys";
import SideMenu from "../../components/menus/SideMenu";

import DynamicSourceManager from "../dialogs/DynamicSourceManager";
import { clone, isnull } from "../../helpers/objects";
import { sourceData } from "../../data/dynamic-sources";
import UnitManager from "../inputs/units/UnitManager";
import Card from "../Card";

const defaultParams = {
	name: "",
	positive: true,
	effort: 1,
	rest: 0,
	units: [],
	tags: [],
	isDynamic: false,
	dynamicSources: [],
	habits: [],
	schedules: []
}

export default {
	components: {
		TextInput,
		DynamicSourceManager,
		SideMenu,
		UnitManager,
		Card,
	},
	props: {
		fnCreate: {
			type: Function,
			default: () => {},
		},
		fnUpdate: {
			type: Function,
			default: () => {},
		},
		fnGet: {
			type: Function,
			default: (id) => ({ id, name: "", units: [] }),
		},
	},
	watch: {
		context(ctx) {
			if (ctx != null) {
				if (ctx.refId) {
					this.heading = `Edit '${ctx.name}'`;
				} else {
					this.heading = `New Action`;
				}

				// if (this.designer.isExisting) {
				// 	if (!this.inline) this.copyData(this.designer.data);
				// 	else this.copyData(this.fnGet(this.designer.inlineId));
				// } else this.copyData({});
				this.copyData(ctx);
			}
		},
	},
	data: () => ({
		heading: "",
		params: clone(defaultParams),
		dynamicSourceIndex: -1,
		dynamicSourceOptions,
		dialogCount: 0,

		showHabits: false,
		showDataSources: false,
		showUnits: false,
		showTags: false,
		showSchedules: false
	}),
	computed: {
		...mapGetters({
			dialog: "dialog",
			context: "tasks/actionContext",
			designer: "tasks/singleActionDesigner",
			unitData: "unitData",
			tags: "tags/data",
			habits: "habits/data",
		}),
		hasParent() {
			return this.context && this.context.parentRefId != null;
		},
		dialogKeys: () => ({ ACTION_DYNAMIC_SOURCES, ACTION_UNITS }),
		active() {
			return this.dialog.current == ACTION;
		},
		sourceData: () => sourceData,

		effortText() {
			switch (this.params.effort) {
				case 1:
					return "Easy";
				case 2:
					return "Medium";
				case 3:
					return "Difficult";
				default:
					return "No Effort";
			}
		},

		positiveOperator() {
			return this.params.positive ? "+" : "-";
		},
		negativeOperator() {
			return this.params.positive ? "-" : "+";
		},
	},
	methods: {
		...mapActions({
			close: "tasks/closeSingleActionDesigner",
			createTask: "tasks/create",
			updateTask: "tasks/update",
			success: "broadcastSuccess",
			pushDialog: "pushDialog",
			popDialog: "popDialog",
			saveActionContext: "tasks/saveActionContext",
			openSchedule: "openSchedule"
		}),
		copyData(data) {
			if (!data) return;

			this.params.name = data.name || "";
			this.params.positive = isnull(data.positive, true);
			this.params.effort = isnull(data.effort, 1);
			this.params.units = clone(data.units || []);
			this.params.tags = clone(data.tags || []);
			this.params.isDynamic = data.isDynamic || false;
			this.params.dynamicSources = clone(data.dynamicSources || []);
			this.params.habits = clone(data.habits || []);
			this.params.schedules = clone(data.schedules || []);
		},

		save() {
			this.saveActionContext(this.params);
			this.close();
		},
		close() {
			this.popDialog();
		},

		addDataSource(source) {
			this.params.dynamicSources.push({
				type: source.type,
			});
			this.showDataSources = true;
			this.popDialog();
		},
		handleSourceClick(index) {
			this.dynamicSourceIndex = index;
			this.pushDialog(ACTION_DYNAMIC_SOURCE_MANAGER);
		},

		addUnit(data) {
			this.params.units.push(data);
			this.showUnits = true;
			this.popDialog();
		},

		async openAddSchedule() {
			const t = this;
			const schedule = await this.openSchedule();

			schedule.onSave(data => {
				t.params.schedules.push(data);
				t.showSchedules = true;
				t.popDialog();
			});
		},
		async openUpdateSchedule(index) {
			const t = this;
			const schedule = await this.openSchedule(this.params.schedules[index]);

			schedule.onSave(data => {
				t.params.schedules[index] = data;
				t.showSchedules = true;
				t.popDialog();
			});
		},
		removeSchedule(index) {
			this.params.schedules.splice(index, 1);
		}
	},
};
</script>
