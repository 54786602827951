<script>
import TextInput from "../TextInput";
import TimeInput from "./TimeInput";

export default {
    components: { TextInput, TimeInput },
    props: {
        value: Array
    },

    data: () => ({
        items: []
    }),

    methods: {
        mapItems() {
            this.items = this.value.map(unit => {
                return {...unit};
            });
        },
        updateUnit(index, value) {
            this.items[index] = value;
            this.$emit("input", this.items);
        }
    },

    watch: {
        "value"() {
            this.mapItems();
        }
    },
    created() {
        this.mapItems();
    },
    render() {
        return <div>
        {this.items.map((unit,i) => {
            switch (unit.type) {
                default: return <TimeInput id={`unit-${i}`} label={unit.type} unitType={unit.type} value={unit} on-input={v => this.updateUnit(i, v)} />
            }
        }).map(ctrl => <div>{ctrl}</div>)}
        </div>
    }
}
</script>